/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

import Head from "next/head";
import { Component } from "react";

interface IProps {
  alt: string;
  description: string;
  slug: string;
  title: string;
  type: string;
  url: string;
}

export default class OpenGraphHead extends Component<IProps> {
  public render() {
    const {
      alt,
      description,
      slug,
      title,
      type,
      url,
    } = this.props;

    return (
      <Head>
        <meta
          content={description}
          property="og:description"
        />
        <meta
          content={require(`../public/chromiums/og-1200x630-${slug}.jpg`) as string}
          property="og:image"
        />
        <meta
          content={alt}
          property="og:image:alt"
        />
        <meta
          content="1200"
          property="og:image:width"
        />
        <meta
          content="630"
          property="og:image:height"
        />
        <meta
          content={title}
          property="og:title"
        />
        <meta
          content={type}
          property="og:type"
        />
        <meta
          content={url}
          property="og:url"
        />
      </Head>
    );
  }
}
