/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

import Head from "next/head";
import { Component } from "react";

interface IProps {
  alt: string;
  description: string;
  slug: string;
  title: string;
}

export default class TwitterHead extends Component<IProps> {
  public render() {
    const {
      alt,
      description,
      slug,
      title,
    } = this.props;

    return (
      <Head>
        <meta
          content="summary"
          name="twitter:card"
        />
        <meta
          content={description}
          name="twitter:description"
        />
        <meta
          content={require(`../public/chromiums/twitter-512x512-${slug}.jpg`) as string}
          name="twitter:image"
        />
        <meta
          content={alt}
          name="twitter:image:alt"
        />
        <meta
          content={title}
          name="twitter:title"
        />
      </Head>
    );
  }
}
